<template>
  <div>
    <fw-heading size="h2">Sincronização de dados com SAP RH</fw-heading>
    <fw-panel
      title="Direitos"
      class="my-5"
      :counter="syncs.worker_rights.length"
      :boxed="syncs.worker_rights.length ? 'xs' : null"
    >
      <template #toolbar>
        <div class="flex items-center">
          <fw-button
            icon="refresh"
            type="link"
            :disabled="!canSyncWorkerRights"
            @click.native="confirmSyncWorkerRights()"
            >Sincronizar direitos</fw-button
          >
          <router-link
            class="text-sm px-3 py-2 text-primary font-bold transition-colors duration-150 border border-transparent focus:outline-none hover:opacity-80"
            :to="
              $router.resolve({
                name: 'manage-vacations-year-sync-logs',
                params: {
                  year: year,
                },
              }).href
            "
            >Ver histórico de alterações</router-link
          >
        </div>
      </template>
      <template #default>
        <RecordManageMapSyncJob
          v-for="(syncJob, i) in syncs.worker_rights"
          :key="`worker_rights_${i}`"
          :sync-job="syncJob"
          context="sync-rights"
          type="list"
        />
        <fw-panel-info v-if="!syncs.worker_rights.length" empty clean
          >Não existem sincronizações pendentes.</fw-panel-info
        >
      </template>
    </fw-panel>

    <fw-panel
      title="Permissões"
      class="my-5"
      :counter="syncs.maps_permissions.length"
      :boxed="syncs.maps_permissions.length ? 'xs' : null"
    >
      <template #toolbar>
        <fw-button
          icon="refresh"
          type="link"
          :disabled="!canSyncSyncMapsPermissions"
          @click.native="confirmSyncMapsPermissions()"
          >Sincronizar permissões</fw-button
        >
      </template>
      <template #default>
        <RecordManageMapSyncJob
          v-for="(syncJob, i) in syncs.maps_permissions"
          :key="`worker_rights_${i}`"
          :sync-job="syncJob"
          context="sync-permissions"
          type="list"
        />
        <fw-panel-info v-if="!syncs.maps_permissions.length" empty clean
          >Não existem sincronizações pendentes.</fw-panel-info
        >
      </template>
    </fw-panel>

    <fw-panel
      title="Dias de férias"
      class="my-5"
      :counter="syncs.myuc_days.length"
      :boxed="syncs.myuc_days.length ? 'xs' : null"
    >
      <template #toolbar>
        <fw-button icon="refresh" type="link" :disabled="!canSyncMyUCDays" @click.native="confirmSendMyUCDaysToSap()"
          >Sincronizar de dias</fw-button
        >
      </template>
      <template #default>
        <RecordManageMapSyncJob
          v-for="(syncJob, i) in syncs.myuc_days"
          :key="`worker_rights_${i}`"
          :sync-job="syncJob"
          context="sync-days"
          type="list"
        />
        <fw-panel-info v-if="!syncs.myuc_days.length" empty clean>Não existem sincronizações pendentes.</fw-panel-info>
      </template>
    </fw-panel>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ year, syncs }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import RecordManageMapSyncJob from '@/components/records/manage/RecordManageMapSyncJob'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    RecordManageMapSyncJob,
  },

  props: {
    year: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      syncs: {
        maps_permissions: [],
        myuc_days: [],
        populate_year_maps: [],
        worker_rights: [],
      },
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    permissions() {
      return this.$store.getters.userPermissions
    },

    canSyncWorkerRights() {
      return this.syncs.worker_rights?.length == 0 || this.syncs.worker_rights?.every(el => el.ended_at)
    },

    canSyncSyncMapsPermissions() {
      return this.syncs.maps_permissions?.length == 0 || this.syncs.maps_permissions?.every(el => el.ended_at)
    },

    canSyncMyUCDays() {
      return this.syncs.myuc_days?.length == 0 || this.syncs.myuc_days?.every(el => !el.error && el.ended_at)
    },
  },

  mounted() {
    this.getSyncs()
  },

  methods: {
    async getSyncs() {
      await utils.tryAndCatch(this, async () => {
        const res = await this.api.getSyncs(this.year)
        this.syncs = res
      })
    },

    confirmSyncWorkerRights() {
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Sincronizar',
        title: 'Sincronizar direitos dos trabalhadores',
        message: `Tem a certeza que deseja continuar? Esta sincronização poderá resultar na criação de novas versões dos mapas.`,
        onConfirm: async () => {
          const syncData = await this.api.syncWorkerRights(this.year)
          this.syncs.worker_rights.push(syncData)
        },
      })
    },

    confirmSyncMapsPermissions() {
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Sincronizar',
        title: 'Sincronizar permissões dos mapas',
        message: `Tem a certeza que deseja continuar?`,
        onConfirm: async () => {
          const syncData = await this.api.syncMapsPermissions(this.year)
          this.syncs.maps_permissions.push(syncData)
        },
      })
    },

    confirmSendMyUCDaysToSap() {
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Sincronizar',
        title: 'Sincronizar dias de férias para SAP',
        message: `Tem a certeza que deseja continuar?`,
        onConfirm: async () => {
          const syncData = await this.api.syncMyUCdays(this.year)
          this.syncs.myuc_days.push(syncData)
        },
      })
    },
  },
}
</script>

<style></style>

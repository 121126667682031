<template>
  <PanelActivity :inject-payload="injectPayload" />
</template>

<script>
import PanelActivity from '@/fw-modules/fw-core-vue/ui/components/panels/PanelActivity'

export default {
  components: {
    PanelActivity,
  },

  props: {
    year: {
      type: Number,
    },
  },

  data() {
    return {
      injectPayload: {
        year: this.year,
        context: 'year',
      },
    }
  },
}
</script>

<template>
  <fw-panel title="Inbox" featured>
    <template #toolbar>
      <b-dropdown aria-role="list" position="is-bottom-left">
        <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
          <fw-icon-more class="w-5 h-5" />
        </fw-button-dropdown>
        <b-dropdown-item aria-role="menuitem">
          Marcar todas como lidas
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <b-tabs v-model="activeTab" :animated="false" @input="getTabData">
      <b-tab-item :label="'Mensagens por ler'" value="unreadMessages">
        <template #header>
          <div class="inline-flex">
            <div>Mensagens por ler</div>

            <div class="text-xs opacity-50 ml-2">
              <span>{{ unreadMessages?.pagination?.total_items || 0 }}</span>
            </div>
          </div>
        </template>
        <div
          v-if="!unreadMessages?.notifications?.length"
          class="flex items-center justify-center text-sm text-gray-400 h-full"
        >
          Não existem mensagens por ler.
        </div>

        <RecordMessage
          v-for="(message, index) in unreadMessages?.notifications"
          :key="`message-${index}`"
          :message="{ ...message, description: message.title }"
          type="minimal"
          is-unread
          :can-edit="false"
        >
          <!-- <template #footer>
            <fw-tag size="xs" type="xlight">Férias 2024</fw-tag>
          </template> -->
        </RecordMessage>
      </b-tab-item>
      <b-tab-item :label="'Ações pendentes'" value="pendingActions">
        <template #header>
          <div class="inline-flex">
            <div>Ações pendentes</div>
            <div class="text-xs opacity-50 ml-2">
              <span>{{ pendingActions?.pagination?.total_items || 0 }}</span>
            </div>
          </div>
        </template>

        <div v-if="!pendingActions?.data?.length" class="flex items-center justify-center text-sm text-gray-400 h-full">
          Não existem ações pendentes.
        </div>

        <RecordManageMapUser
          v-for="item in pendingActions?.data"
          :key="item.context_key"
          :item="item.details"
          :user="pendingActions?.users?.[item.details.user_key]"
        ></RecordManageMapUser>
      </b-tab-item>
    </b-tabs>

    <BlockPagination
      v-if="showPagination"
      :per-page="pagination?.active_limit ?? 25"
      :total="pagination?.total_items ?? 0"
      :total-pages="pagination?.total_pages ?? 0"
      :current="pagination?.current_page ?? 1"
      @page-changed="pageChanged"
    />

    <fw-panel-info debug label="Map data (raw)">
      <json-viewer :value="{ pendingActions, unreadMessages, pagination, counter }"></json-viewer>
    </fw-panel-info>
  </fw-panel>
</template>

<script>
import RecordMessage from '@/fw-modules/fw-core-vue/ui/components/records/RecordMessage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import RecordManageMapUser from '@/components/records/manage/RecordManageMapUser'
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'

export default {
  components: {
    RecordMessage,
    RecordManageMapUser,
    BlockPagination,
  },

  props: {
    year: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      activeTab: 'pendingActions',
      unreadMessages: {},
      pendingActions: {},
      loading: {
        messages: true,
        pendingActions: true,
      },
      currentPage: 1,
      currentlimit: 50,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    counter() {
      if (this.activeTab == 'unreadMessages') this.unreadMessages?.notifications?.length

      return this.pendingActions?.data?.length
    },

    pagination() {
      if (this.activeTab == 'unreadMessages') return this.unreadMessages?.pagination
      return this.pendingActions?.pagination
    },

    showPagination() {
      if (this.activeTab == 'unreadMessages') return this.pagination?.total_pages > 1 && !this.loading.messages
      return this.pagination?.total_pages > 1 && !this.loading.pendingActions
    },
  },

  mounted() {
    this.getPendingActions()
    this.getUnreadMessages()
  },

  methods: {
    getTabData(tab) {
      if (tab == 'unreadMessages') this.getUnreadMessages()
      else if (tab == 'pendingActions') this.getPendingActions()
    },

    setUrlParams() {
      let query = {}

      query['p'] = this.currentPage

      this.$router.push({ path: this.$route.path, query: query })
    },

    pageChanged(page) {
      this.currentPage = page
      this.setUrlParams()
      this.getTabData(this.activeTab)
    },

    async getUnreadMessages() {
      this.loading.messages = true

      await utils.tryAndCatch(this, async () => {
        const res = await this.api.getPendingActions({
          page: this.currentPage,
          limit: this.currentLimit,
          order: 'created_at',
          direction: 'desc',
          context: 'messages',
          seen: false,
          year: this.year,
        })
        console.log('unreadMessages', res)
        this.unreadMessages = res
        this.currentPage = res.pagination.current_page
      })

      this.loading.messages = false
    },

    async getPendingActions() {
      this.loading.pendingActions = true

      await utils.tryAndCatch(this, async () => {
        const res = await this.api.getPendingActions({
          page: this.currentPage,
          limit: this.currentLimit,
          order: 'created_at',
          direction: 'desc',
          context: 'map_user',
          year: this.year,
        })
        console.log('pendingActions', res)
        this.pendingActions = res
        this.currentPage = res.pagination.current_page
      })

      this.loading.pendingActions = false
    },
  },
}
</script>

<template>
  <div
    class="relative rounded-lg p-2 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left gap-5 group"
    :class="{ 'bg-primary bg-opacity-10': selected }"
  >
    <a :href="openMapPath" class="flex gap-3 flex-1">
      <div>
        <fw-icon-group class="w-6 h-6 flex-shrink-0 text-gray-500" :class="{ 'text-primary': selected }" />
      </div>
      <div class="flex-1 font-medium items-center flex gap-5">
        <v-clamp autoresize :max-lines="1">
          {{ map.title }}
        </v-clamp>
        <div>
          <fw-tag v-if="map.status == 'open'" type="light-primary" size="xs">Disponível</fw-tag>
          <fw-tag v-else type="light-orange" size="xs">Não disponível</fw-tag>
        </div>
        <div class="flex-1"></div>
        <div class="text-gray-500 text-xs flex gap-3 items-center font-semibold">
          <div class="flex gap-1 min-w-10">
            <fw-icon-people class="w-4 h-4 flex-shrink-0" />
            <div class="text-gray-500">{{ map.stats?.map_users ?? 0 }}</div>
          </div>
          <div v-if="map.stats" class="flex gap-1">
            <fw-icon-actions
              class="w-4 h-4 flex-shrink-0"
              :class="map.stats?.pending_actions > 0 ? 'text-yellow-600' : 'text-gray-300'"
            />
          </div>
          <div v-if="map.stats && (userPermissions.isManager || userPermissions.isAdmin)" class="flex gap-1">
            <fw-icon-alert
              class="w-4 h-4 flex-shrink-0"
              :class="map.stats?.errors > 0 ? 'text-yellow-600' : 'text-gray-300'"
            />
          </div>
        </div>
      </div>
    </a>
    <div class="flex gap-2 items-center group-hover:opacity-100">
      <fw-button size="xs" type="transparent" @click.native="$emit('filter', map)">
        <fw-icon-filter class="w-5 h-5 flex-shrink-0 opacity-50 mr-1" :class="{ 'text-primary': selected }" />
        <fw-icon-checkbox-circle v-if="selected" class="w-5 h-5 flex-shrink-0 text-primary" />
        <fw-icon-add v-else class="w-5 h-5 flex-shrink-0 opacity-40" />
      </fw-button>
    </div>
  </div>
</template>

<script>
import { MAP_STATUS_COLORS } from '@/utils/index.js'

export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
    map: {
      type: Object,
    },
    //   {
    //     "key": "65f09e9b",
    //     "year": 2023,
    //     "period_key": "fae25ffa",
    //     "manager_key": "tkolrs",
    //     "manager_category": "Diretor de Serviços",
    //     "manager_creditdays_key": "tkolrs",
    //     "sub_manager_keys": null,
    //     "sub_manager_creditdays_keys": null,
    //     "reporter_keys": null,
    //     "global_reporter_keys": null,
    //     "status": "open",
    //     "is_teacher": false,
    //     "title": "Administração | Centro de Serviços Comuns | Serviço Gestão Instalações e Património | Divisão Empreitadas e Projetos",
    //     "organic_unit": "Serviço Gestão Instalações e Património",
    //     "department": "Divisão Empreitadas e Projetos",
    //     "org": "uc",
    //     "created_at": "2023-03-08T04:06:11.729223+00:00",
    //     "updated_at": null
    // },
  },

  data() {
    return {
      colors: MAP_STATUS_COLORS,
    }
  },

  computed: {
    lang() {
      return this.$store.state.language
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    openMapPath() {
      return this.$router.resolve({
        name: 'manage-map-users',
        params: {
          key: this.map.key,
        },
      }).href
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "createdAt": "Criado em",
    "status": {
      "draft": "Rascunho",
      "published": "Publicado",
      "canceled": "Cancelado"
    }
  },
  "en": {
    "createdAt": "Created at",
    "status": {
      "draft": "Draft",
      "published": "Published",
      "canceled": "Cancelado"
    }
  }
}
</i18n>

<template>
  <div
    class="relative rounded-lg py-1 pl-1 pr-4 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex flex-col gap-4 text-left"
  >
    <button
      role="button"
      class="text-left flex gap-2 items-center"
      :class="{ 'text-primary': expanded }"
      @click="$emit('expand')"
    >
      <fw-icon-arrow-right class="font-medium w-5 h-5 text-gray-500" />
      <div class="flex-1">
        Sync para o ano {{ parsedMessage.year }}
        {{ parsedMessage.employee_numbers?.length ? `Números ${parsedMessage.employee_numbers}` : '' }}
      </div>
      <div class="text-gray-500 text-xs">Terminado em {{ item.created_at | formatDateTime }}</div>

      <div>
        <fw-icon-chevron-up v-if="expanded" class="w-5 h-5" />
        <fw-icon-chevron-down v-else class="w-5 h-5" />
      </div>
    </button>

    <div v-if="expanded">
      <fw-panel-info v-if="isObjectEmpty(item.data)" empty>
        Sem alterações
      </fw-panel-info>

      <template v-else>
        <fw-panel-info
          v-for="(data, userNumber) in item.data"
          :key="userNumber"
          clean
          class="flex flex-col border-t border-gray-200 p-2"
        >
          <fw-person no-style size="micro" :person="users[userNumber]" paddingless> </fw-person>

          <p v-if="data.worker_sap_info">
            {{ data.worker_sap_info }}
          </p>

          <div
            v-if="data.new_map_user"
            class="mt-2 mb-1"
            muted
            :set="(userMapKey = Object.keys(data.new_map_user).at(-1))"
          >
            <a
              v-if="userMaps[userMapKey]"
              class="font-bold underline text-gray-500"
              :href="
                $router.resolve({
                  name: 'manage-map-user',
                  params: {
                    year: userMaps[userMapKey].year,
                    userKey: userMaps[userMapKey].user_key,
                    mapUserKey: userMaps[userMapKey].key,
                    key: userMaps[userMapKey].map_key,
                  },
                }).href
              "
              >Novo mapa criado</a
            >
            <span v-else>Novo mapa criado</span>
          </div>

          <div v-else-if="data.map_user_key" class="mt-2 mb-1" muted>
            <a
              v-if="userMaps[data.map_user_key]"
              class="font-bold underline text-gray-500"
              :href="
                $router.resolve({
                  name: 'manage-map-user',
                  params: {
                    year: userMaps[data.map_user_key].year,
                    userKey: userMaps[data.map_user_key].user_key,
                    mapUserKey: userMaps[data.map_user_key].key,
                    key: userMaps[data.map_user_key].map_key,
                  },
                }).href
              "
              >Ver mapa</a
            >
          </div>

          <div v-if="data.total || data.days_total || data.days_compensated || data.days_credit || data.birthday" clean>
            <fw-label>Direitos:</fw-label>
            <p v-if="data.total">Dias total: {{ data.total.current }} -> {{ data.total.new }}</p>
            <p v-if="data.days_total">Dias disponíveis: {{ data.days_total.current }} -> {{ data.days_total.new }}</p>
            <p v-if="data.days_compensated">
              Dias cumulados do ano anterior: {{ data.days_compensated.current }} -> {{ data.days_compensated.new }}
            </p>
            <p v-if="data.days_credit">Dias crédito: {{ data.days_credit.current }} -> {{ data.days_credit.new }}</p>
            <p v-if="data.birthday">Dia de aniversário: {{ data.birthday.current }} -> {{ data.birthday.new }}</p>
          </div>

          <div v-if="data.contingentes">
            <fw-label>Contingentes:</fw-label>

            <p>De:</p>
            <p v-if="!data.contingentes.current.length" class="pl-4">Sem contingentes</p>
            <ul v-else class="list-inside">
              <li v-for="(c, i) in data.contingentes.current" :key="i" class="pl-4">
                {{
                  `${c.days} dias no contingente ${c.number} de ${c.year}, dedutível de ${c.deductible_from} a ${c.deductible_until}`
                }};
              </li>
            </ul>
            <p>Para:</p>
            <p v-if="!data.contingentes.new.length" class="pl-4">Sem contingentes</p>
            <ul v-else class="list-inside">
              <li v-for="(c, i) in data.contingentes.new" :key="i" class="pl-4">
                {{
                  `${c.days} dias no contingente ${c.number} de ${c.year}, dedutível de ${c.deductible_from} a ${c.deductible_until}`
                }};
              </li>
            </ul>
          </div>

          <div v-if="data.missing_days_in_myuc || data.missing_days_in_sap" clean>
            <fw-label>Dias:</fw-label>
            <p v-if="data.missing_days_in_myuc">
              Registados em SAP mas não em MyUC:
              {{
                Array.isArray(data.missing_days_in_myuc)
                  ? data.missing_days_in_myuc.join(', ')
                  : data.missing_days_in_myuc
                      .replace('Created days registered in SAP but not in MyUC: ', '')
                      .replaceAll("'", '')
                      .replace('[', '')
                      .replace(']', '')
              }}
            </p>
            <p v-if="data.missing_days_in_sap">
              Registados em MyUC mas não em SAP:
              {{
                Array.isArray(data.missing_days_in_sap)
                  ? data.missing_days_in_sap.join(', ')
                  : data.missing_days_in_sap
                      .replace('Created days registered in MyUC but not in SAP: ', '')
                      .replaceAll("'", '')
                      .replace('[', '')
                      .replace(']', '')
              }}
            </p>
          </div>
        </fw-panel-info>
      </template>
    </div>
  </div>
</template>

<script>
import { MAP_USER_STATUS_COLORS, MAP_USER_STATUS, MAP_USER_VERSION_REASONS } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {},

  props: {
    users: {
      type: Object,
      default: () => {},
    },
    userMaps: {
      type: Object,
      default: () => {},
    },
    item: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      versionsMotives: MAP_USER_VERSION_REASONS,
      colors: MAP_USER_STATUS_COLORS,
      statusLabels: MAP_USER_STATUS,
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },

    userPermissions() {
      return this.$store.getters.userPermissions
    },

    parsedMessage() {
      // Ex: {"year": 2024, "exclude_numbers": [], "employee_numbers": [41938]}
      let res = {}
      let parts = this.item.message.split(';')

      for (const part of parts) {
        const x = part.trim().split(':')
        const key = x[0].trim()
        let value = x[1].trim().replaceAll("'", '')
        if (key != 'year') {
          value = value.replace('[', '').replace(']', '')
        }
        res[key] = value
      }
      return res
    },

    openMapPath() {
      return this.$router.resolve({
        name: 'manage-map-user',
        params: {
          year: this.item.year,
          userKey: this.item.user_key,
          mapUserKey: this.item.key,
          key: this.item.map_key,
        },
      }).href
    },
  },

  methods: {
    isObjectEmpty(obj) {
      return utils.isObjectEmpty(obj)
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "status": {
      "open": "Aberto",
      "closed": "Submetido",
      "submitted": "Submetido",
      "sealed": "Fechado"
    }
  },
  "en": {
    "status": {
      "open": "Aberto",
      "closed": "Submetido",
      "submitted": "Submetido",
      "sealed": "Fechado"
    }
  }
}
</i18n>

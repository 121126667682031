<template>
  <div>
    <fw-panel title="Resultados" :counter="logs.length" :counter-total="totalResults" class="my-5">
      <template #toolbar>
        <b-dropdown aria-role="list" position="is-bottom-left">
          <fw-button-dropdown
            slot="trigger"
            aria-role="listitem"
            type="xlight"
            :label="ranges[selectedRange].label"
            class="font-medium text-gray-500"
          >
          </fw-button-dropdown>
          <b-dropdown-item
            v-for="(value, key) in ranges"
            :key="key"
            :label="value.label"
            aria-role="listitem"
            :class="`font-medium ${key == selectedRange ? 'text-primary' : 'text-gray-500'}`"
            @click.native="selectRange(key)"
          >
            {{ value.label }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <LoadingPlaceholder v-if="loading" />

      <fw-panel-info v-else-if="!loading && !logs.length" empty>
        Sem resultados a mostrar.
      </fw-panel-info>

      <div v-for="item in logs" :key="item.key" class="p-1 border-b border-gray-100">
        <RecordSyncRightsLog
          :item="item"
          :users="users"
          :user-maps="userMaps"
          :expanded="expanded === item.key"
          @expand="toggleExpand(item)"
        />
      </div>

      <BlockPagination
        v-if="totalPages > 1 && !loading"
        :per-page="limit"
        :total="totalResults"
        :total-pages="totalPages"
        :current.sync="page"
        @page-changed="pageChanged"
      />
    </fw-panel>

    <fw-panel-info label="Data (raw)" class="mt-4">
      <json-viewer :value="{ logs, users, userMaps }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import BlockPagination from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockPagination'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import RecordSyncRightsLog from '@/components/records/RecordSyncRightsLog.vue'

export default {
  components: {
    BlockPagination,
    LoadingPlaceholder,
    RecordSyncRightsLog,
  },

  data() {
    return {
      filterByType: 'all',
      orderBy: [
        {
          key: 'created_at',
          label: 'Data de criação',
          type: 'string',
        },
      ],
      searchInput: '',
      orderByValue: 'created_at',
      orderDirection: 'DESC',
      page: 1,
      totalResults: 0,
      totalPages: 1,
      limit: 25,
      logs: [],
      users: {},
      userMaps: {},
      loading: true,
      expanded: null,
      today: Dates.now(),
      selectedRange: 'last7Days',
    }
  },

  computed: {
    user() {
      return this.$store.getters.getUser
    },

    api() {
      return this.$store.state.api.base
    },

    ranges() {
      return {
        last7Days: {
          label: this.$t('lastDays', { days: 7 }),
          startDate: this.today.subtract(7, 'days').format('YYYY-MM-DD'),
        },
        last30Days: {
          label: this.$t('lastDays', { days: 30 }),
          startDate: this.today.subtract(30, 'days').format('YYYY-MM-DD'),
        },
      }
    },
  },

  mounted() {
    this.getUrlParams()
    this.getSyncsLogs()
  },

  methods: {
    toggleExpand(item) {
      if (this.expanded && this.expanded === item.key) this.expanded = null
      else this.expanded = item.key
    },

    selectRange(rangeKey) {
      this.selectedRange = rangeKey
      this.getSyncsLogs()
    },

    getUrlParams() {
      if (this.$route.query.q) {
        this.searchInput = this.$route.query.q
      }

      if (this.$route.query.s) {
        this.orderByValue = this.$route.query.s
        this.orderDirection =
          this.$route.query.o == 'ASC' || this.$route.query.o == 'DESC' ? this.$route.query.o : 'none'
      }

      if (this.$route.query.p) {
        this.page = parseInt(this.$route.query.p)
      }
    },

    setUrlParams() {
      let query = {}
      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      if (this.orderByValue.length > 0) {
        query['s'] = this.orderByValue
        query['o'] = this.orderDirection
      }

      query['p'] = this.page

      this.$router.push({ path: this.$route.path, query: query })
    },

    sortOrderChanged(newSort) {
      if (newSort != null && newSort.key != null) {
        this.orderByValue = newSort.key.key
        this.orderDirection = newSort.direction
      }
      this.setUrlParams()
      this.getSyncsLogs()
    },

    async getSyncsLogs() {
      this.loading = true
      const query = {
        limit: this.limit,
        page: this.page,
        start_date: this.ranges[this.selectedRange].startDate,
      }
      console.log('query', query)
      if (this.orderByValue.length > 0) {
        query['sort'] = this.orderByValue
        query['direction'] = this.orderDirection.toLowerCase()
      }

      if (this.searchInput.length > 0) {
        query['query'] = this.searchInput
      }

      await utils.tryAndCatch(this, async () => {
        const result = await this.api.getSyncsLogs(query)
        console.log('getSyncsLogs :>> ', result)
        this.totalResults = result.pagination?.total_items
        this.totalPages = result.pagination?.total_pages
        this.page = result.pagination?.current_page
        this.logs = result.logs
        this.userMaps = result.user_maps
        this.users = { ...this.users, ...result.users }
      })

      this.loading = false
    },

    pageChanged(page) {
      console.log('pageChanged to :>> ', page)
      if (page) this.page = page
      this.setUrlParams()
      this.getSyncsLogs()
    },

    parseDatesForPayload(formatedDate) {
      if (formatedDate) {
        return Dates.from(formatedDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      return formatedDate
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "lastDays": "Últimos {days} dias"
  },
  "en": {
    "lastDays": "Last {days} days"
  }
}
</i18n>

<template>
  <SidebarBase sticky>
    <template #main>
      <div class="flex-1">
        <ButtonSidebar
          :active="!view || view == 'dashboard'"
          label="Dashboard"
          icon="dashboard"
          :to="`/manage/vacations/${year}`"
        />

        <ButtonSidebar :to="`/manage/vacations/${year}/maps`" label="Mapas" class="rounded-md" :active="view == 'maps'">
          <template #svg>
            <fw-icon-calendar class="w-6 h-6 " />
          </template>
        </ButtonSidebar>

        <ButtonSidebar
          v-if="permissions.isAdmin || permissions.isManager"
          :active="view == 'sync'"
          label="Sincronização"
          icon="refresh"
          :to="`/manage/vacations/${year}/sync`"
        />

        <ButtonSidebar
          :to="`/manage/vacations/${year}/activity`"
          label="Atividade"
          class="rounded-md"
          :active="view == 'activity'"
        >
          <template #svg>
            <fw-icon-activity class="w-6 h-6 " />
          </template>
        </ButtonSidebar>

        <ButtonSidebar
          :to="`/manage/vacations/${year}/notifications`"
          label="Notificações"
          class="rounded-md"
          :active="view == 'notifications'"
        >
          <template #svg>
            <fw-icon-message-sent class="w-6 h-6 " />
          </template>
        </ButtonSidebar>
      </div>
    </template>
  </SidebarBase>
</template>

<script>
import SidebarBase from '@/fw-modules/fw-core-vue/ui/components/sidebars/SidebarBase'
import ButtonSidebar from '@/fw-modules/fw-core-vue/ui/components/buttons/ButtonSidebar'
// import VerticalSteps from '@/fw-modules/fw-core-vue/ui/components/buttons/VerticalSteps'

export default {
  components: {
    ButtonSidebar,
    SidebarBase,
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },

    view() {
      return this.$route.meta.view ?? 'dashboard'
    },

    year() {
      return this.$route.params.year
    },

    permissions() {
      return this.$store.getters.userPermissions
    },
  },

  methods: {},
}
</script>

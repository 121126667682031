<template>
  <div>
    Settings...
  </div>
</template>

<script>
export default {
  props: {
    year: {
      type: Number,
    },
  },

  data() {
    return {}
  },

  computed: {},
}
</script>
